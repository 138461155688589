import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link, useIntl } from 'gatsby-plugin-intl';
import { Element } from 'react-scroll';
import SEO from 'components/seo/seo';
import Layout from 'layouts/Layout';
import SubpageWrapper from 'components/Subpage/SubpageWrapper/SubpageWrapper';
import Heading from 'components/Subpage/Heading/Heading';
import Footer from 'components/Footer/Footer';
import withLocale from 'hoc/withLocale';

import {
  StyledSectionWrapper,
  StyledPageList,
  StyledPageItem,
} from 'components/Subpage/SectionList/SectionListStyles';

const SubpageList = ({ currentLocale }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      pagesPl: allStrapiPages(filter: { locale: { eq: "pl" } }) {
        nodes {
          title
          slug
          locale
        }
      }
      pagesEn: allStrapiPages(filter: { locale: { eq: "en" } }) {
        nodes {
          title
          slug
          locale
        }
      }
      metaPl: strapiHomepageMetatags(locale: { eq: "pl" }) {
        SEO {
          meta_title
          meta_description
          image_alt
          meta_image {
            localFile {
              url
            }
          }
        }
      }
      metaEn: strapiHomepageMetatags(locale: { eq: "en" }) {
        SEO {
          meta_title
          meta_description
          image_alt
          meta_image {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  let meta;
  if (currentLocale === 'pl') meta = data.metaPl;
  else if (currentLocale === 'en') meta = data.metaEn;

  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
    image_alt: imgAlt,
    meta_image: metaImage,
  } = meta.SEO;

  let page;
  if (currentLocale === 'pl') page = data.pagesPl;
  else if (currentLocale === 'en') page = data.pagesEn;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={metaImage && metaImage.localFile.url}
        imgAlt={imgAlt}
      />
      <SubpageWrapper>
        <Heading>{intl.formatMessage({ id: 'subpages_title' })}</Heading>
        <StyledSectionWrapper>
          <StyledPageList>
            {page.nodes.map(item => (
              <StyledPageItem>
                <Link to={`/${item.slug}`}>{item.title}</Link>
              </StyledPageItem>
            ))}
          </StyledPageList>
        </StyledSectionWrapper>
      </SubpageWrapper>
      <Element name="navigation_footer">
        <Footer />
      </Element>
    </Layout>
  );
};

export default withLocale(SubpageList);
