import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const rem = px => `${(px / 16) * 1}rem`;

export const StyledSectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledSectionContentWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${({ theme }) => theme.mq.lg} {
    justify-content: ${({ align }) =>
      align === 'right' ? 'flex-end' : 'flex-start'};
    flex-direction: ${({ align }) =>
      align === 'right' ? 'row-reverse' : 'row'};
  }
`;

export const StyledTextWrapper = styled('div')`
  ${({ theme }) => theme.mq.lg} {
    padding: 0 28px;
  }
`;

export const StyledImageSectionWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  height: 718px;
  grid-gap: 18px;
  width: 100%;
  grid-template-areas:
    'img1'
    'img2'
    'img3';
  ${({ theme }) => theme.mq.lg} {
    height: 518px;
    grid-gap: 28px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'img1 img2'
      'img1 img3';
  }
`;

export const StyledImg = styled('img')`
  max-width: 100%;
  width: 100%;
  margin-bottom: 2.4rem;
  ${({ theme }) => theme.mq.lg} {
    max-width: 320px;
  }
  ${({ theme }) => theme.mq.xl} {
    max-width: 360px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 480px;
  }
`;

export const StyledImgFullWidth = styled('img')`
  width: 100%;
  margin-bottom: 2.4rem;
`;

export const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 100%;
  margin-bottom: 2.4rem;
`;

export const StyledImage = styled('div')`
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url(${({ src }) => src});
  &:first-child {
    grid-area: img1;
  }
  &:nth-child(2) {
    grid-area: img2;
  }
  &:nth-child(3) {
    grid-area: img3;
  }
`;

export const StyledPageList = styled('ul')`
  margin: 2rem 0 2rem 0;
  width: 100%;
`;

export const StyledPageItem = styled('li')`
  list-style: none outside none;
  padding-left: ${rem(20)};
  position: relative;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.black};

  &::before {
    content: '';
    position: absolute;
    top: ${rem(5)};
    left: 0;
    width: ${rem(14)};
    height: ${rem(14)};
    display: inline-block;
    border: solid #fff ${rem(2)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.orange};
  }
  a {
    font-size: inherit;
    color: inherit;
    &:hover {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`;

export const StyledFirstText = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
`;

export const StyledVideoWrapper = styled('div')`
  width: 100%;
  video {
    display: block !important;
  }
`;

export const StyledUrlVideoWrapper = styled('div')`
  width: 100%;
  & > div {
    height: auto !important;
    width: auto !important;
    & > div {
      position: relative !important;
      padding-bottom: 56.25% !important;
      height: 0 !important;
    }
  }
  iframe {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
`;
