import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
  StyledPageWrapper,
  StyledBackgroundWrapper,
  StyledBackground,
  StyledInnerWrapper,
} from './SubpageWrapperStyles';

const SubpageWrapper = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "inquiry-form-bg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const gatsbyImage = getImage(data.file);

  return (
    <StyledPageWrapper>
      <StyledBackgroundWrapper>
        <StyledBackground alt="maszyna" image={gatsbyImage} />
      </StyledBackgroundWrapper>
      <StyledInnerWrapper>{children}</StyledInnerWrapper>
    </StyledPageWrapper>
  );
};

export default SubpageWrapper;
